import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ITask } from '../../types/Task';
import UserAvatar from '../UserAvatar';
import { addInteraction, getTaskInteractions, removeTask, updateCard, api } from '../../services/api'
import { CustomUseContext } from '../../utils/storeApi';
import { MoreVert, MoreVertOutlined, PlaylistAddCheck } from "@material-ui/icons";
import { SelectAutocomplete } from '../SelectAutocomplete';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import { Interaction, InteractionParentType, InteractionType } from '../../types/Interaction';
import { toast } from "react-toastify";
import { CustomTimeline } from './Components/Timeline';

type TaskDetailsProps = {
  open: boolean;
  setOpen: (newOpenValue: boolean) => void;
  task?: ITask;
}

export default function TaskDetails({ open, setOpen, task }: TaskDetailsProps) {
  const contextAtt = CustomUseContext();
  const cancelButtonRef = useRef(null);
  const [comment, setComment] = useState('');
  const [interactions, setInteractions] = useState([]);
  const removeTaskCb = async () => {
    removeTask(contextAtt!.currentProject!.id!, task!.id);
    setOpen(false);
  };
  const { t, } = useTranslation();

  const parseInteractionToTimeline = (interaction: Interaction) => {
    const member = contextAtt.members.find((member) => member.id === interaction.userId);
    let title = member?.name;

    if (interaction.type === InteractionType.CREATED) {
      title += ` created this issue`
    }
    if (interaction.type === InteractionType.COMMENTED) {
      title += ` commented:`
    }
    return {
      icon: () => <Avatar sx={{ width: 18, height: 18 }}><p style={{ fontSize: '15px' }}>{member?.name[0]}</p></Avatar>,
      time: new Date(interaction.created_at!.seconds! * 1000),
      title: title,
      content: interaction.value,
    }
  }

  const insertComment = async () => {
    const interactionAdded = await addInteraction({
      userId: contextAtt.user!.id!,
      parentId: task?.id,
      parentType: InteractionParentType.TASK,
      projectId: contextAtt.currentProject!.id,
      type: InteractionType.COMMENTED,
      value: comment,
    });

    // @ts-ignore
    setInteractions((oldValue) => (
      [parseInteractionToTimeline(interactionAdded!), ...oldValue]
    ));

    setComment('');
  }

  // const deleteComment = async (commentId: string) => {
  //   try {
  //     await api.deleteComment(commentId);

  //     const newComments = interactions.filter((interaction) => interaction.id !== commentId);
  //     setInteractions(newComments);
  //   } catch (error) {
  //     toast.error(t(`Error on Delete Comment`), {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // }

  useEffect(() => {
    async function setup() {
      if (!task) {
        setInteractions([]);
        return;
      };
      // @ts-ignore
      const taskInteractions: Interaction[] = await getTaskInteractions(contextAtt.currentProject!.id, task!.id);
      const mappedInteraction = taskInteractions?.map((interaction) => {
        return parseInteractionToTimeline(interaction);
      });

      // @ts-ignore
      setInteractions(mappedInteraction);
    };
    setup();
  }, [task]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-3/4 h-3/4">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PlaylistAddCheck className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full" style={{ minHeight: '250px' }}>
                      <Dialog.Title as="h3"
                        suppressContentEditableWarning={true}
                        contentEditable
                        className="text-lg leading-6 font-medium text-gray-900"
                        onInput={(e: any) => {
                          updateCard(contextAtt!.currentProject!.id!, task!.id, { title: e.currentTarget.textContent })
                        }}
                      >
                        {task?.title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className='grid grid-cols-3 pb-10'>
                          <div className='col-span-2 flex flex-col'>
                            <div className='flex flex-col min-h-[150px]'>
                              <p>{t('Common.description')}:</p>
                              <div
                                suppressContentEditableWarning={true}
                                contentEditable
                                className="flex text-md leading-6 font-small text-gray-900 h-full hover:border-2"
                                onInput={(e: any) => {
                                  updateCard(contextAtt!.currentProject!.id, task!.id, { description: e.currentTarget.textContent })
                                }}
                              >
                                {task?.description}
                              </div>
                            </div>
                            <div className='flex flex-col'>
                              <textarea
                                rows={2}
                                cols={40}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                className="mb-3"
                              />
                              <div className='flex justify-end'>
                                <button
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                  onClick={insertComment}
                                >
                                  {t('Common.insertComment')}
                                </button>
                              </div>
                              <CustomTimeline events={interactions} />
                            </div>
                            {/* <p className="text-sm text-gray-500">
                              Are you sure you want to deactivate your account? All of your data will be permanently
                              removed. This action cannot be undone.
                            </p> */}
                            {/* Subtasks:
                            <ul>
                              <li>
                                <div className="flex flex-row">
                                  RAM - 122 Criar tela com formulários  15 (pontos) <UserAvatar widht={10} />   TO DO
                                </div>
                              </li>
                              <li>
                                <div className="flex flex-row">
                                  RAM - 123 Integrar tela de formulários  25 (pontos) <UserAvatar widht={10} />   Bloqueado
                                </div>
                              </li>
                            </ul> */}
                          </div>
                          <div>
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={removeTaskCb}
                            >
                              {t('Common.delete')}
                            </button>
                            <div>
                              <SelectAutocomplete
                                label={t('Common.responsable')}
                                items={[...contextAtt.members, { id: 'unassigned', name: 'Unassigned' }]}
                                onSelectedChange={(item) => {
                                  if (item.id === 'unassigned') {
                                    return updateCard(contextAtt!.currentProject!.id, task!.id, { assigned: null });
                                  }

                                  updateCard(contextAtt!.currentProject!.id!, task!.id, { assigned: item.id });
                                }}
                                defaultValue={task?.assigned || 'unassigned'}
                              />
                            </div>
                            {/* <div className="flex space-x-1">
                              <p>
                                Status:
                              </p>
                              To do
                            </div> */}
                            {/* <div className="flex space-x-1">
                              <p>
                                Prioridade:
                              </p>
                              Alta
                            </div> */}
                            {/* <div className="flex space-x-1">
                              <p>
                                Estimativa:
                              </p>
                              8 pontos
                            </div> */}
                            {/* <div className="flex space-x-1">
                              <p>
                                Labels:
                              </p>
                              Bug
                            </div> */}
                            {/* <div className="flex space-x-1">
                              <p>
                                Responsável:
                              </p>
                              <UserAvatar widht={10} />
                            </div> */}
                            {/* <div className="flex space-x-1">
                              <p>
                                Reviewer:
                              </p>
                              <UserAvatar widht={10} />
                            </div> */}
                          </div>
                        </div>
                        {/* <div>
                          timeline
                          <div className="flex space-x-1">
                            <p>
                              Criado em:
                            </p>
                            30/08/2022 as 14h30
                          </div>
                          <div className="flex space-x-1">
                            <p>
                              Atualizado em:
                            </p>
                            30/08/2022 as 15h30
                          </div>
                          <div className="flex space-x-1">
                            <p>
                              Criador:
                            </p>
                            <UserAvatar widht={10} />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  {/* <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Deactivate
                  </button> */}
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {t('Common.close')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}