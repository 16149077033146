import { en, ptBr } from "../screens/Backlog/translations/index";

import { myIssues } from "../screens/MyIssues/translations/index";
import { Common } from './Common';
import { Members } from '../screens/Settings/Members/translations'
import { Auth } from '../screens/Auth/translations';
import { Onboarding } from '../screens/Onboarding/translations';

const resources = {
  en: {
    translations: {
      backlog: en,
      myIssues: myIssues.en,
      Common: Common.en,
      Members: Members.en,
      Auth: Auth.en,
      Onboarding: Onboarding.en,
    }
  },
  'pt-BR': {
    translations: {
      backlog: ptBr,
      myIssues: myIssues.ptBr,
      Common: Common.ptBr,
      Members: Members.ptBr,
      Auth: Auth.ptBr,
      Onboarding: Onboarding.ptBr,
    }
  }
};


export { resources }