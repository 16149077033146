import Select from "../../../components/Select";
import Header from "../components/Header";

export default function Workflow() {
  const parentsStatus = [
    {
      name: 'Backlog',
      enableResponsibleTeam: false,
      status: [
        {
          name: 'Backlog'
        }
      ],
    },
    {
      name: 'Unstarded',
      enableResponsibleTeam: true,
      status: [
        {
          name: 'To Do'
        }
      ],
    },
    {
      name: 'Started',
      enableResponsibleTeam: true,
      status: [
        {
          name: 'In Progress',
        },
        {
          name: 'Code Review'
        },
      ],
    },
    {
      name: 'Pending',
      enableResponsibleTeam: true,
      status: [
        {
          name: 'Blocked'
        }
      ],
    },
    {
      name: 'Completed',
      status: [
        {
          name: 'Done'
        }
      ],
    },
    {
      name: 'Canceled',
      status: [
        {
          name: 'Canceled'
        }
      ],
    },
  ]
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <Header title="Fluxo" description="Gerencie as configurações do processo de trabalho como status e atribuições" />
        <div className="mt-6 w-2/5">
          <div className="flex flex-col">
            {
              parentsStatus.map((parentStatus, parentIndex) => (
                <div key={parentIndex} className="mb-6">
                  <div className="flex">
                    <div className="ml-5 font-semibold mb-3">
                      {parentStatus.name}
                    </div>
                    {!!parentStatus.enableResponsibleTeam && (
                      <div className="ml-10">Time responsável?</div>
                    )}
                  </div>
                  <ul>
                    {
                      parentStatus.status.map((childStatus, childIndex) => (
                        <li key={childIndex} className="flex flex-row items-center bg-slate-300">
                          <div className="flex flex-row ml-4 mr-2">
                            <p className="m-5">{childStatus.name}</p>
                            {!!parentStatus.enableResponsibleTeam && (
                              <Select
                                className="m-5 w-52"
                                options={[{ name: 'QA' }, { name: 'Reviewers' }, { name: 'Developers' }, { name: 'Responsável da Tarefa' }]}
                              />
                            )}
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}