import { signInWithEmailAndPassword } from "firebase/auth";
import { firebase } from "../../services/Firebase";
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { BallLoading } from "../../components/Loading/Ball";
import { CustomDialog } from '../../components/CustomDialog';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const errorAndsTranslations = {
  'auth/user-not-found': 'userNotFound',
  'auth/wrong-password': 'wrongPassword',
  'auth/too-many-requests': 'manyRequests',
};

export function Sign() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [waitingNavitation, setWaitingNavitation] = useState(false);
  const { t, } = useTranslation();

  const signupUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    signInWithEmailAndPassword(firebase.auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user) {
          setWaitingNavitation(true);
        }
      })
      .catch((error) => {
        console.log('error', error)
        const errorCode = error.code;
        const errorMessage = error.message;
        // @ts-ignore
        const translationKey = errorAndsTranslations[error?.code!]

        toast.error(t(`Auth.errors.${translationKey}`), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <CustomDialog 
        open={waitingNavitation} 
        setOpen={setWaitingNavitation} 
        title={t('Auth.loggedWithSucess')} 
        body={() => <>{`${t('Auth.waitingRedirect')}`}</>} 
      />
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <h1 className="text-center font-bold text-3xl text-purple-900">Vysen</h1>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              {t('Auth.signInYourAccount')}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {t('Common.or')}{' '}
              <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
                {t('Auth.orSignupToCreate')}
              </Link>
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={signupUser}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div className="">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder={t('Common.emailAddress')}
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}

                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder={t('Common.password')}
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:opacity-75"
                  disabled={loading}
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  {t('Auth.rememberMe')}
                </label>
              </div>

              <div className="text-sm">
                <Link to="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                  {t('Auth.forgoutYourPassword')}
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-75"
                disabled={loading}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  {/* <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" /> */}
                </span>
                {t('Auth.sign')}
                {loading && (
                  <div className="ml-2">
                    <BallLoading height="" />
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
