import { Link } from "react-router-dom";


export default function MenuItem({ label, icon, aditionalLabel,  counter, to }: { label: string, icon: () => JSX.Element, aditionalLabel?: string, counter?: number, to?: string }) {
  return (
    <li>
      <Link 
        className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700" 
        to={to || '/'}
      >
        {icon()}
        <span className="flex-1 ml-3 whitespace-nowrap">{label}</span>
        { aditionalLabel && (
          <span className="inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span>
        )}
        { counter && (
          <span className="inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full dark:bg-blue-900 dark:text-blue-200">3</span>
        )}
      </Link>
    </li>
  )
}