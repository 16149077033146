import React from "react";
import Navbar from "../components/Layout/Navbar";
import SideNav from "../components/Layout/SideNav";

//@ts-ignore
export default function MainLayout(props: React.Props) {
  return (
    <div className="flex w-full h-screen">
      <SideNav />
      <div className="w-full">
        <Navbar />
        <div>
          {props.children}
        </div>
      </div>
    </ div>
  )
}