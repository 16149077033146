import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import InputContainer from "../components/InputContainer";
import List from "../components/List";

import store from "../utils/store";
import StoreApi, { CustomUseContext } from "../utils/storeApi";

import "./styles.scss";
import TaskDetails from "../components/TaskDetails";
import { ITask } from '../types/Task';
import { IContextType } from '../utils/storeApi'
import { addNewTask, getProjectStatus, getTasks, updateCardStatus, getLastTask } from "../services/api";

const dataStorage = JSON.parse(window.localStorage.getItem("dataKanban"));


const initialState = () => {
  if (dataStorage) {
    return dataStorage;
  } else {
    window.localStorage.setItem("dataKanban", JSON.stringify(store));
    return store;
  }
};

export default function Home() {
  const contextAtt = CustomUseContext();
  const [loading, setLoading] = useState(true);
  const [boardLists, _setBoardLists] = useState({});
  const boardListstRef = React.useRef(boardLists);
  const setBoardLists = data => {
    boardListstRef.current = data;
    _setBoardLists(data);
  };

  const [data, setData] = useState(initialState);
  const { setSelectedTask, selectedTask } = contextAtt;
  
  useEffect(() => {
    let unsubscribe;
    async function getProjectDetails() {
      const projectDetails = await getProjectStatus(contextAtt.currentProject.id);
      const collumns = {};
      projectDetails.forEach((status) => {
        collumns[status.id] = {
          ...status,
          cards: [],
        }
      });
      setBoardLists(collumns)
      setLoading(false)
      
    }

    async function getTasksLists() {
      const callbackListener = (newTasks) => {
        const newTaskLists = structuredClone(boardListstRef.current);

        newTasks.forEach((taskToCheck) => {
          if (taskToCheck.type === 'added' || taskToCheck.type === 'new') {
            newTaskLists[taskToCheck.data.statusId].cards.push(taskToCheck.data)
          }

          if (taskToCheck.type === 'removed') {
            const taskId = taskToCheck.data.id;
            const statusId = taskToCheck.data.statusId;
            const taskIndex = boardListstRef.current[statusId].cards.findIndex((task) => {
              if (task.id === taskId) {
                return true;
              }

              return false;
            })

            newTaskLists[statusId].cards.splice(taskIndex, 1);
          }

          if (taskToCheck.type === "modified" ) {
            const taskId = taskToCheck.data.id;
            // look on all to find
            let targetListId;
            let targedCardIndex;
            Object.values(boardListstRef.current).find((list, listIndex) => {
              const findCard = list.cards.find((card, cardIndex) => {
                if (card.id === taskId) {
                  targetListId = list.id;
                  targedCardIndex = cardIndex
                  return true;
                }

                return false;
              });
              return findCard;
            });

            const findedCard = boardListstRef.current[targetListId].cards[targedCardIndex];

            // remove
            newTaskLists[findedCard.statusId].cards.splice(targedCardIndex, 1)

            // add the new version
            newTaskLists[taskToCheck.data.statusId].cards.push(taskToCheck.data)

            // @todo check if is a opened Task
            // console.log('a modificada estava aberta? ', selectedTask, taskToCheck.data)
            // if (selectedTask?.id === taskToCheck.data.id) {
            // }
            // setSelectedTask()
          }
        });

        setBoardLists(newTaskLists)
      }
      const tasksResult = await getTasks(contextAtt.currentProject.id, callbackListener);
      unsubscribe = tasksResult.unsubscribe;
    }

    async function setupBoard() {
      await getProjectDetails();
      await getTasksLists();
    }
    setupBoard();

    return () => {
      unsubscribe();
    }
  }, []);

  const addMoreCard = (title, listId) => {
    if (!title) {
      return;
    }

    const newCardId = uuid();
    const newCard: ITask = {
      id: newCardId,
      title,
      statusId: listId,
    };
    addNewTask(newCard, contextAtt.currentProject.id, contextAtt.user.id);
  };
  const removeCard = (index, listId) => {
    const list = data.lists[listId];

    list.cards.splice(index, 1);

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
    window.localStorage.setItem("dataKanban", JSON.stringify(newState));
  };

  const updateCardTitle = (title, index, listId) => {
    const list = data.lists[listId];
    list.cards[index].title = title;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
    window.localStorage.setItem("dataKanban", JSON.stringify(newState));
  };
  const addMoreList = (title) => {
    if (!title) {
      return;
    }

    const newListId = uuid();
    const newList = {
      id: newListId,
      title,
      cards: [],
    };
    const newState = {
      listIds: [...data.listIds, newListId],
      lists: {
        ...data.lists,
        [newListId]: newList,
      },
    };
    setData(newState);
    window.localStorage.setItem("dataKanban", JSON.stringify(newState));
  };

  const updateListTitle = (title, listId) => {
    const list = data.lists[listId];
    list.title = title;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };

    setData(newState);
    window.localStorage.setItem("dataKanban", JSON.stringify(newState));
  };

  const deleteList = (listId) => {
    const lists = data.lists;
    const listIds = data.listIds;

    delete lists[listId];

    listIds.splice(listIds.indexOf(listId), 1);

    const newState = {
      lists: lists,
      listIds: listIds,
    };

    setData(newState);
    window.localStorage.setItem("dataKanban", JSON.stringify(newState));
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (type === "list") {
      const newListIds = data.listIds;

      newListIds.splice(source.index, 1);
      newListIds.splice(destination.index, 0, draggableId);

      const newState = {
        ...data,
        listIds: newListIds,
      };
      setData(newState);
      window.localStorage.setItem("dataKanban", JSON.stringify(newState));

      return;
    }

    const sourceList = boardLists[source.droppableId];
    const destinationList = boardLists[destination.droppableId];
    const draggingCard = sourceList.cards.filter(
      (card) => card.id === draggableId
    )[0];

    if (source.droppableId === destination.droppableId) {
      sourceList.cards.splice(source.index, 1);
      destinationList.cards.splice(destination.index, 0, draggingCard);

      // const newState = {
      //   ...data,
      //   lists: {
      //     ...data.lists,
      //     [sourceList.id]: destinationList,
      //   },
      // };
      // setData(newState);
      // window.localStorage.setItem("dataKanban", JSON.stringify(newState));
    } else {
      // offline
      // sourceList.cards.splice(source.index, 1);
      // destinationList.cards.splice(destination.index, 0, draggingCard);

      updateCardStatus(draggableId, destination.droppableId)
      // const newState = {
      //   ...data,
      //   lists: {
      //     ...data.lists,
      //     [sourceList.id]: sourceList,
      //     [destinationList.id]: destinationList,
      //   },
      // };

      // setData(newState);
      // window.localStorage.setItem("dataKanban", JSON.stringify(newState));
    }
  };

  const clickedCard = (cardDetails: ITask) => {
    console.log('clickedCard fn ', cardDetails)
    setSelectedTask(cardDetails)
  }

  const context: IContextType = {
    clickedCard,
  }

  if (loading) {
    return (
      <h1>Loading</h1>
    )
  }

  return (
    <>
      <TaskDetails
        open={!!selectedTask} 
        task={selectedTask}
        setOpen={() => setSelectedTask(null)} 
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="app" type="list" direction="horizontal">
          {(provided) => (
            <div
              className="wrapper"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {Object.values(boardLists).map((list, index) => {
                return <List list={list} key={list.id} index={index} />;
              })}
              {/* ADD NEW LIST ON BOARD: DISABLED FOR V1 */}
              {/* <div>
                <InputContainer type="list" />
              </div> */}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
