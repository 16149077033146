import UserAvatar from "../../../components/UserAvatar";
import Header from "../components/Header";

export default function Teams() {
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <Header title="Times" description="Você pode agrupar pessoas por times e usar em atribuições" />

        <div className="mt-6">
          Mobile
          <ul>
            <li className="flex items-center">
              <UserAvatar widht={15} />
              <div className="flex flex-col ml-4 mr-2">
                <p>Jon San</p>
                <p>jonsan@gmail.com</p>
              </div>
              <div className="ml-2 mr-2">
                admin
              </div>
            </li>
          </ul>
        </div>
        <div className="mt-6">
          QA
          <ul>
            <li className="flex items-center">
              <UserAvatar widht={15} />
              <div className="flex flex-col ml-4 mr-2">
                <p>Ava</p>
                <p>ava@gmail.com</p>
              </div>
              <div className="ml-2 mr-2">
                admin
              </div>
            </li>
          </ul>
        </div>
        <div className="mt-6">
          Backend
          <ul>
            <li className="flex items-center">
              <UserAvatar widht={15} />
              <div className="flex flex-col ml-4 mr-2">
                <p>Ava</p>
                <p>ava@gmail.com</p>
              </div>
              <div className="ml-2 mr-2">
                admin
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}