import React, { useState } from "react";

import TextareaAutosize from "react-textarea-autosize";
import { Draggable } from "react-beautiful-dnd";

import storeApi, { CustomUseContext } from "../../utils/storeApi";

import "./styles.scss";
import UserAvatar from "../UserAvatar";
import Tag from "../Tag";
import { ITask } from "../../types/Task";

type CardProps = {
  card: ITask;
  index: number;
  listId: string;
}

export default function Card({ card, index, listId }: CardProps) {
  const [open, setOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(card.title);
  // @ts-ignore
  const contextAtt = CustomUseContext();
  const { clickedCard, updateCardTitle } = contextAtt;

  const handleOnBlur = () => {
    updateCardTitle(newTitle, index, listId);
    setOpen(!open);
  };

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div 
            className="card-content p-2 bg-sky-500 hover:bg-sky-700"
            onClick={() => {clickedCard(card)}}
          >
            {open ? (
              <TextareaAutosize
                //@ts-ignore
                type="text"
                className="input-card-title"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                onBlur={handleOnBlur}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleOnBlur();
                  }
                  return;
                }}
                autoFocus
              />
            ) : (
              <div>
                <div className="grid grid-cols-2">
                  <div className="flex justify-start content-center w-full">
                    <p className="text-xs	">
                      {contextAtt.currentProject?.taskCode}-{card.taskNumber}
                    </p>
                  </div>
                  <div className="flex justify-end">
                    {
                      card.assigned && (
                        <UserAvatar widht={5}/>
                      )
                    }
                  </div>
                </div>
                <div className="flex card-title-container">
                  <p>{card.title}</p>
                </div>
                {/* <button
                  onClick={() => {
                    removeCard(index, listId);
                  }}
                >
                  <DeleteOutline />
                </button> */}
                {/* <Tag label="15"/> */}
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}
