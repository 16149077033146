import { Fragment, useEffect, useRef, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { UnfoldMore, CheckCircle } from "@material-ui/icons";

export type Item = { id: string, name: string }

export function SelectAutocomplete({ label, items, onSelectedChange, defaultValue }: { label: string, items: Array<Item>, onSelectedChange: (item: Item) => void, defaultValue?: string }) {
  const [selected, setSelected] = useState<undefined | Item>(undefined);
  const [query, setQuery] = useState('');
  const refButton = useRef();

  const filteredPeople =
    query === ''
      ? items
      : items.filter((item) =>
        item.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )

  const selectedChange = (item: Item) => {
    setSelected(item);
    onSelectedChange(item);
  }

  useEffect(() => {
    if (!defaultValue) return;

    const defaultSelected: undefined | Item = items.find((item) => item.id === defaultValue);
    setSelected(defaultSelected)
  }, [defaultValue])

  return (
    <div className="">
      <Combobox value={selected} onChange={selectedChange}>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Label>{label}:</Combobox.Label>
                <Combobox.Input
                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                  // @ts-ignore
                  displayValue={(person) => person?.name}
                  onChange={(event) => setQuery(event.target.value)}
                  // @ts-ignore
                  onClick={() => refButton?.current?.click?.()}
                />
                {/* 
// @ts-ignore */}

                <Combobox.Button ref={refButton} className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <UnfoldMore className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                {(ref) => {
                  return (
                    <div ref={ref}>
                      <Combobox.Options static className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredPeople.length === 0 && query !== '' ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            Nothing found.
                          </div>
                        ) : (
                          filteredPeople.map((person) => (
                            <Combobox.Option
                              key={person.id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                }`
                              }
                              value={person}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {person.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                        }`}
                                    >
                                      <CheckCircle className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </div>
                  )
                }}
              </Transition>
            </div>
          </>
        )}
      </Combobox>
    </div>
  )
}
