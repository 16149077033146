import Header from "../components/Header";

export default function General() {
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <Header title="Geral" description="Configure informações sobre seu projeto" />
      </div>
      <div className="flex justify-center">
        <form className="max-w-3xl"	>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nome</label>
              <input 
                type="text" 
                id="name" 
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Web App" 
                required 
              />
            </div>
            <div>
              <label htmlFor="identifier" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Identificador</label>
              <input 
                type="text" 
                id="identifier" 
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                placeholder="TAL" 
                required 
              />
            </div>
          </div>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Salvar
          </button>
        </form>
      </div>

    </>
  )
}