import { useEffect, useState } from "react";
import UserAvatar from "../../../components/UserAvatar";
import { AddWorkspaceMember, deleteWorkspaceMember, getProjectsByWorkspaceId, getWorkspaceMembers, setupWorkspace } from "../../../services/api";
import Header from "../components/Header";
import { MemberStatus, IMember } from '../../../types/Member'
import { DropdownMenu } from "../../../components/DropdownMenu";
import { CustomDialog } from "../../../components/CustomDialog";
import { useTranslation } from "react-i18next";
import { CustomUseContext } from "../../../utils/storeApi";

export default function Members() {
  const [members, setMembers] = useState([]);
  const contextAtt = CustomUseContext();
  const { t } = useTranslation();
  const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);
  const [memberFormValues, setMemberFormValues] = useState({
    name: '',
    email: '',
  });

  async function setup() {
    const members: IMember[] = await getWorkspaceMembers(contextAtt.user.workspaces[0]);
    setMembers(members);
  }

  async function addMember() {
    await AddWorkspaceMember({
      ...memberFormValues,
      workspaceId: contextAtt.user.workspaces[0],
      projectIds: contextAtt.user.projects,
      inviteFromDetails: {
        name: contextAtt.user.name,
        email: contextAtt.user.email,
      },
    });
    setShowAddMemberDialog(false);
    setup();
  }

  function setValues(newValue) {
    setMemberFormValues({
      ...memberFormValues,
      ...newValue,
    })
  }

  const translateStatus = (status = MemberStatus) => {
    const translations = {
      [MemberStatus.ACCEPTED]: 'accepted',
      [MemberStatus.INVITE_SENT]: 'pending',
    };

    return translations[status] || translations['pending'];
  }

  useEffect(() => {
    setup();
  }, []);

  return (
    <div className="flex flex-col justify-center items-center">
      <Header title={t('Members.title')} description={t('Members.description')} />

      <CustomDialog
        open={showAddMemberDialog}
        setOpen={() => setShowAddMemberDialog(false)}
        title={t('Members.AddNewMember.title')}
        body={() => addMemberForm(setValues, t)}
        confirmButton={{ label: t('Members.AddNewMember.add'), click: addMember }}
        cancelButton={{ label: t('Members.AddNewMember.cancel'), }}
      />
      <div className="mt-6">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => setShowAddMemberDialog(true)}
        >
          {t('Members.addMember')}
        </button>
        <div className="overflow-x-auto min-h-[10rem] pb-11	">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Email
                </th>
                <th scope="col" className="py-3 px-6">
                  Name
                </th>
                <th scope="col" className="py-3 px-6">
                  Role
                </th>
                <th scope="col" className="py-3 px-6">
                  Status
                </th>
                <th scope="col" className="py-3 px-6">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {members.map((member) => (
                <tr key={member.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {member.email}
                  </th>
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {member.name}
                  </th>
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {member.role}
                  </th>
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {t(`Common.${translateStatus(member.status)}`)}
                  </th>
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <DropdownMenu
                        deleteClick={async () => {
                          await deleteWorkspaceMember(contextAtt.user.workspaces[0], member.id);
                          setup();
                        }}
                      />
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const addMemberForm = (setValues, t) => {
  return (
    <form className="max-w-3xl"	>
      <div className="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{t('Members.AddNewMember.name')}</label>
          <input
            type="text"
            id="name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Jon"
            required
            onChange={(e) => setValues({ name: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
          <input
            type="text"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="jon@gmail.com"
            required
            onChange={(e) => setValues({ email: e.target.value })}
          />
        </div>
      </div>
    </form>
  )
}