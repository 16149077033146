import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMyIssues } from "../../services/api";
import { ITask } from "../../types/Task";
import { Card } from "./components/Card";
import { CustomUseContext } from '../../utils/storeApi'
import TaskDetails from "../../components/TaskDetails";

export default function MyIssues() {
  const [priorities, setPriorities] = useState([]);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const { t, } = useTranslation();
  const context = CustomUseContext();

  useEffect(() => {
    async function setupIssues() {
      // @ts-ignore
      const myIssues = await getMyIssues(context.currentProject?.id, context.user?.id);
      // @ts-ignore
      const doneStatus = Object.values(context.status).find((status => status.type === 'DONE'));
      // @ts-ignore
      const unfinishedPriorities = myIssues?.filter((issue) => issue.statusId !== doneStatus?.id);
      // @ts-ignore
      setPriorities(unfinishedPriorities)
    }
    setupIssues()
  }, []);

  return (
    <div>
      {t('myIssues.hi')},{' '}<b>{context.user?.name?.split(' ')?.[0]}</b>.

      {' '}{t('myIssues.youHave', { tasksCount: priorities.length })}
      <TaskDetails
        open={!!selectedTask}
        task={selectedTask}
        setOpen={() => setSelectedTask(undefined)}
      />
      <div className="flex flex-col items-center">
        {priorities.map((priority: ITask, index) => {
          return (
            <Card
              key={priority.id}
              id={priority.id}
              index={index}
              title={priority.title || "Staging PT-232"}
              body={priority.description}
              people={[{ field: t('Common.responsable'), assigned: priority.assigned, }]}
              onClick={(index, key) => {
                setSelectedTask(priorities[index])
              }}
              status={context!.status![priority.statusId].title!}
            />
          )
        })}
      </div>

    </div>
  )
}