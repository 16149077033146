type User = { imageUrl: string; name: string };

export default function UserAvatar({ user, users, widht }: { user?: User, users?: User[], widht: number }) {
  if (user?.imageUrl) {
    return <img className="w-10 h-10 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt="Rounded avatar" />
  }

  if (user?.name) {
    return (
      <div className="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600">
        <span className="font-medium text-gray-600 dark:text-gray-300">JL</span>
      </div>
    )
  }

  if (users) {
    <div className="flex -space-x-4">
      <img className="w-10 h-10 rounded-full border-2 border-white dark:border-gray-800" src="/docs/images/people/profile-picture-5.jpg" alt="" />
      <img className="w-10 h-10 rounded-full border-2 border-white dark:border-gray-800" src="/docs/images/people/profile-picture-2.jpg" alt="" />
      <img className="w-10 h-10 rounded-full border-2 border-white dark:border-gray-800" src="/docs/images/people/profile-picture-3.jpg" alt="" />
      <a className="flex justify-center items-center w-10 h-10 text-xs font-medium text-white bg-gray-700 rounded-full border-2 border-white hover:bg-gray-600 dark:border-gray-800" href="/">+99</a>
    </div>
  }

  // 10
  // 12
  return (
    <div className="overflow-hidden relative w-6 h-6 bg-gray-100 rounded-full dark:bg-gray-600">
      <svg className="absolute -left-1 w-8 h-8 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
    </div>
  )
}