import { createContext, useContext } from "react";
import { IProject } from "../types/IProject";
import { IStatus } from "../types/IStatus";
import { IMember } from "../types/Member";
import { ITask } from "../types/Task";
import { IUser } from "../types/User";

export interface IContextType {
    clickedCard: (card: ITask) => void;
    user?: IUser | null;
    setUser: (user: IUser) => void;
    currentProject?: IProject,
    setCurrentProject: (projectId: string, project: IProject) => void;
    addMoreCard: (title: string, listId: string) => void,
    // addMoreList?: (title: string) => void,
    // updateListTitle?: (title: string, listId: string) => void,
    // removeCard?: (index: number, listId: string) => void,
    // deleteList?: (listId: string) => void,
    updateCardTitle: (title: string, index: number, listId: string) => void,
    selectedTask: ITask | null,
    setSelectedTask: (task: ITask) => void,
    projects: Array<IProject> | null,
    setProjects: (projects: Array<IProject>) => void,
    members: Array<IMember>,
    setMembers: (members: Array<IMember>) => void,
    status?: {
        [id:string] : IStatus,
    },
    setStatus: (status: IStatus) => void,
}

// @ts-ignore
const context = createContext<IContextType>({} as IContextType);

export function CustomUseContext() {
    let a = useContext(context);
    return a
}

export default context