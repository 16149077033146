import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import { format, formatDistance, subDays } from 'date-fns'

type Event = {
  icon: () => React.ReactElement;
  time: Date;
  title: string;
  content: string;
}

const CustomTimelineEvent = ({ event }: { event: Event }) => {
  const [showExactTime, setShowExactTime] = React.useState(false);

  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        variant="body2"
        color="text.secondary"
        onClick={() => setShowExactTime(!showExactTime)}
        className="cursor-pointer"
      >
        {
          showExactTime
            ? format(event.time, 'h:mm aaa MMM/dd/yyyy')
            : formatDistance(event.time, new Date(), { addSuffix: true })
        }
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        {event.icon()}
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="body2" component="span" fontWeight={600}>
          {event.title}
        </Typography>
        <Typography variant="body2">{event.content}</Typography>
      </TimelineContent>
    </TimelineItem>
  )
}


export function CustomTimeline({ events = [] }: { events: Event[] }) {
  return (
    <Timeline position="right">
      {events.map((event, index) => (
        <CustomTimelineEvent event={event} key={index} />
      ))}
    </Timeline>
  );
}
