import { sendPasswordResetEmail } from "firebase/auth";
import { firebase } from "../../services/Firebase";
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

export function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate()

  const signupUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(email)
        sendPasswordResetEmail(firebase.auth, email)
        .then(() => {
            // Password reset email sent!
            // ..
            console.log("deu certo")
        })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("errado", error)
        // ..
    });
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <h1 className="text-center font-bold text-3xl text-purple-900">Vysen</h1>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Signup to create a new account
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <Link to="/sign" className="font-medium text-indigo-600 hover:text-indigo-500">
                Sign in your account
              </Link>
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={signupUser}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div className="mb-6">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                  
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  {/* <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" /> */}
                </span>
                Signup
              </button>
              <button
                className="group relative flex w-full justify-center rounded-md border border-transparent mt-5 py-2 px-4 text-sm font-medium text-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                type="button"
                onClick={(event) => { event.preventDefault(); navigate(-1)}}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  {/* <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" /> */}
                </span>
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
