import React from "react";
import Bookmark from "@material-ui/icons/Bookmark";
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import "./styles.scss";
import { Avatar } from "@material-ui/core";
import { firebase } from "../../../services/Firebase";
import { CustomUseContext } from "../../../utils/storeApi";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const contextAtt= CustomUseContext();
  const { t } = useTranslation();
  const logout = async () => {
    try {
      await firebase.auth.signOut()
    } catch (error) {
      console.log('logout firebase error', error);
    }
  }

  return (
    <div>
      <nav>
        <div className="nav-container">
          <div className="flex grow">
            <h1 className="text-center font-bold text-3xl text-purple-900">Vysen</h1>
          </div>
          <div className="flex items-center">
            <div className="flex items-center">
              <Menu as="div" className="flex items-center">
                <div className="flex justify-end justify-items-end">
                  <Menu.Button className="flex items-center	inline-flex justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    {contextAtt.user?.name}
                    <Avatar />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-50 top-12 absolute mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active ? 'bg-violet-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={logout}
                          >
                            {t('Common.logout')}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
