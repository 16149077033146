const en = require('./en.json');
const ptBr = require('./prBr.json');


const Members = {
  en,
  ptBr,
}

export {
  Members
};