import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addUserOnProject, getInviteDetails, getProjectsByWorkspaceId, getUserByEmail, getUserOrCreate, updateInviteStatus } from "../../services/api";
import { firebase } from "../../services/Firebase";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Accept() {
  const query = useQuery();
  const navigate = useNavigate()
  const [inviteDetails, setInviteDetails] = useState(null);
  const [error, setError] = useState<'MISSING_PARAMS' | 'INVITE_NOT_FOUND' | null>(null);
  const [status, setStatus] = useState<'WITHOUT_ACCOUNT_CREATED' | 'ACCEPT_INVITE' | null>(null);
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [userInvited, setUserInvited] = useState(null);

  useEffect(() => {
    async function setup() {
      if (!query.get('workspace') || !query.get('invite')) {
        setLoading(false);
        setError("MISSING_PARAMS");
        return
      }
      const inviteDetails = await getInviteDetails(query.get('workspace')!, query.get('invite')!);
      if (!inviteDetails) {
        setError("INVITE_NOT_FOUND");
        setLoading(false);
        return;
      }

      // @ts-ignore
      setInviteDetails(inviteDetails);

      // @ts-ignore
      const userAccount = await getUserByEmail(inviteDetails?.email);
      // @ts-ignore
      setUserInvited(userAccount);
      if (userAccount) {
        setStatus("ACCEPT_INVITE");
      } else {
        setStatus("WITHOUT_ACCOUNT_CREATED");
      }

      setLoading(false);
    };

    setup();
  }, []);

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (status === "ACCEPT_INVITE") {
      // @ts-ignore
      await addUserOnProject(userInvited.id!, query.get('invite'))
      await updateInviteStatus(query.get('workspace')!, query.get('invite')!, 'ACCEPTED');
      // navigate('/board');
      window.location.replace('/board');

      return;
    }

    try {
      // @ts-ignore
      const userCreated = await createUserWithEmailAndPassword(firebase.auth, inviteDetails?.email!, password);
      const userOnApi = await getUserOrCreate(userCreated.user.uid, {
        // @ts-ignore
        email: userCreated.user.email,
        onboardingDone: true,
        projects: [
          // @ts-ignore
          ...inviteDetails.projects,
        ],
        workspaces: [
          query.get('workspace')!,
        ]
      }, true);

      await updateInviteStatus(query.get('workspace')!, query.get('invite')!, 'ACCEPTED');
      // navigate('/board');
      window.location.replace('/board');
      // @ts-ignore
      // await addUserOnProject(contextAtt.user?.id!, query.get('invite')!)
      // const userRef = doc(firestoreDb, "users", userId);
      // transaction.update(userRef, {
      //   onboardingDone: true,
      //   workspace: {
      //     id: workspaceId,
      //   },
      //   projects: [
      //     projectId,
      //   ]
      // });
      console.log('userOnApi accept', userOnApi)
    } catch (error) {
      setLoading(false);
    }

  }

  if (loading) {
    return <>LOADING....</>
  }

  if (error === 'MISSING_PARAMS') {
    return <>MISSING PARAMS ON QUERY....</>
  }

  if (error === 'INVITE_NOT_FOUND') {
    return <>This invite is no available...</>
  }

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Você recebeu um invite para se juntar a um projeto Vysen
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {/* Or{' '} */}
            {/* <Link to="/sign" className="font-medium text-indigo-600 hover:text-indigo-500">
                Sign in your account
              </Link> */}
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={submitForm}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div className="mb-6">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
                disabled={true}
                // onChange={(event) => setEmail(event.target.value)}
                // @ts-ignore
                value={inviteDetails?.email!}

              />
            </div>
            {status === 'WITHOUT_ACCOUNT_CREATED' && (
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                />
              </div>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {status === 'WITHOUT_ACCOUNT_CREATED' && 'Criar conta'}
              {status === 'ACCEPT_INVITE' && 'Aceitar convite'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}