import { firestoreDb } from "./Firebase";
import { collection, deleteDoc, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { ITask } from '../types/Task'
import { v4 as uuid } from "uuid";
import { IUser } from "../types/User";
import { IProject } from "../types/IProject";
import { Interaction, InteractionParentType, InteractionType } from "../types/Interaction";

const logger = {
  info: console.log,
  error: console.log,
}

const getTasks = (projectId: string, listenerCallback: (any: any) => void) => {
  const q = query(collection(firestoreDb, "tasks"), where('projectId', '==', projectId));
  return new Promise((resolve) => {
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const result: Array<{ type: string, data: any }> = [];
      snapshot.docChanges().forEach((change) => {

        result.push({
          type: change.type,
          data: change.doc.data(),
        });
      });
      listenerCallback(result);
      resolve({ unsubscribe, result });
    });
  })
}

const getProjectStatus = async (projectId: string) => {
  try {
    const q = query(collection(firestoreDb, "projects", projectId, 'status'));
  
    const querySnapshot = await getDocs(q,);
    return querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
      //@ts-ignore
    }).sort((a, b) => a.order - b.order)
  } catch (error) {
    logger.error('getProjectStatus ', error)
  }
};

const getWorkspaceMembers = async (workspaceId: string) => {
  try {
    const q = query(collection(firestoreDb, "workspaces", workspaceId, 'members'));
  
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
    })
  } catch (error) {
    logger.error('getWorkspaceMembers', error);
  }
}

const deleteWorkspaceMember = async (workspaceId: string, memberId: string) => {
  const memberRed = doc(firestoreDb, "workspaces", workspaceId, "members", memberId);
  await deleteDoc(memberRed);
}

const AddWorkspaceMember = async ({ email, workspaceId, projectIds,  inviteFromDetails}: { email: string, workspaceId: string, projectIds: string[], inviteFromDetails: { name: string, email: string} }) => {
  const inviteId = uuid();
  const memberRef = doc(firestoreDb, "workspaces", workspaceId, "members", inviteId);
  await setDoc(memberRef, {
    email,
    status: 'PENDING',
    created_at: serverTimestamp(),
    role: 'Admin',
    projects: [...projectIds],
    inviteSentFrom: {
      name: inviteFromDetails.name,
      email: inviteFromDetails.email,
    },
  });
}

const setupWorkspace = async (userInfo: IUser, project: IProject) => {
  const workspaceId = uuid();
  const projectId = uuid();

  try {
    await runTransaction(firestoreDb, async (transaction) => {
      const workspaceCollection = doc(firestoreDb, "workspaces", workspaceId);
      transaction.set(workspaceCollection, {
        userOwnerId: userInfo.id,
        created_at: serverTimestamp(),
      });

      const projectCollection = doc(firestoreDb, "projects", projectId);
      transaction.set(projectCollection, {
        name: project.name,
        taskCode: project.taskCode,
        organizationOwnerId: workspaceId,
        created_at: serverTimestamp(),
      });

      const userRef = doc(firestoreDb, "users", userInfo.id!);
      transaction.update(userRef, {
        onboardingDone: true,
        workspaces: [
          workspaceId,
        ],
        projects: [
          projectId,
        ]
      });
    });


    await runTransaction(firestoreDb, async (transaction) => {
      const statusCollectionBacklog = doc(firestoreDb, "projects", projectId, "status", uuid());
      transaction.set(statusCollectionBacklog, {
        type: 'BACKLOG',
        title: "backlog",
        order: 0,
      });


      const statusCollectionTodo = doc(firestoreDb, "projects", projectId, "status", uuid());
      transaction.set(statusCollectionTodo, {
        type: 'TODO',
        title: "to do",
        order: 1,
      });

      const statusCollectionDoing = doc(firestoreDb, "projects", projectId, "status", uuid());
      transaction.set(statusCollectionDoing, {
        type: 'IN_PROGRESS',
        title: "doing",
        order: 2,
      },
      )

      const statusCollectionDone = doc(firestoreDb, "projects", projectId, "status", uuid());
      transaction.set(statusCollectionDone, {
        type: "DONE",
        title: "done",
        order: 3,
      });

      const statusCollectionCanceled = doc(firestoreDb, "projects", projectId, "status", uuid());
      transaction.set(statusCollectionCanceled, {
        type: "CANCELED",
        title: "canceled",
        order: 4,
      });
 
      const workspaceMembersCollection = doc(firestoreDb, "workspaces", workspaceId, 'members', uuid());
      transaction.set(workspaceMembersCollection, {
        userId: userInfo.id,
        role: 'Admin',
        name: userInfo.name,
        email: userInfo.email,
        status: 'ACCEPTED',
        created_at: serverTimestamp(),
      });
    })
    console.log("Transaction successfully committed!");

    return {
      project: {
        id: projectId,
      },
    }
  } catch (e) {
    console.log("Transaction failed: ", e);
  }

}

const setupProject = async (projectId: string) => {
  return;

  // status id??
  // const firstTask: ITask = {
  //   id: uuid(),
  //   title: 'Your first task',
  //   description: 'Description of your fist task',
  // }
  // const tasksCollection = doc(firestoreDb, "projects", projectId, "tasks", firstTask.id);
  // await setDoc(tasksCollection, firstTask)

  const statusCollectionTodo = doc(firestoreDb, "projects", projectId, "status", uuid());
  await setDoc(statusCollectionTodo, {
    type: 'TODO',
    title: "to do",
    order: 0,
  },
  )

  const statusCollectionDoing = doc(firestoreDb, "projects", projectId, "status", uuid());
  await setDoc(statusCollectionDoing, {
    type: 'IN_PROGRESS',
    title: "doing",
    order: 1,
  },
  )

  const statusCollectionDone = doc(firestoreDb, "projects", projectId, "status", uuid());
  await setDoc(statusCollectionDone, {
    type: "DONE",
    title: "done",
    order: 2,
  },
  )
}
const updateCardStatus = async (taskId: string, newStatusId: string) => {
  try {
    const taskRef = doc(firestoreDb, "tasks", taskId);
    await updateDoc(taskRef, {
      statusId: newStatusId
    });
  } catch (error) {
    logger.error('updateCardStatus', error);
  }
}

const updateCard = async (projectId: string, taskId: string, newTaskValues: { title?: string, description?: string, assigned?: string | null }) => {
  const taskRef = doc(firestoreDb, "tasks", taskId);
  await updateDoc(taskRef, {
    ...newTaskValues,
  },
  )
}

const removeTask = async (projectId: string, taskId: string) => {
  try {
    const taskRef = doc(firestoreDb, "tasks", taskId);
    await deleteDoc(taskRef);
  } catch (error) {
    logger.error('removeTask', error, taskId)
  }
}

const getLastTask = async (projectId: string) => {
  try {
    const q = query(collection(firestoreDb, "tasks"), where("projectId", "==", projectId), orderBy('created_at', 'desc'), limit(1));

    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
    });

    return docs?.[0]
  } catch (error) {
    logger.error('getLastTask', error)
  }
}

const addNewTask = async (taskValues: ITask, projectId: string, userId: string) => {
  try {
    const lastTaskCreated = await getLastTask(projectId);

    let taskNumber = 1;
    // @ts-ignore
    if (lastTaskCreated && lastTaskCreated.taskNumber) {
      // @ts-ignore
      taskNumber = lastTaskCreated.taskNumber + 1;
    }
    const projectRef = doc(firestoreDb, "tasks", taskValues.id);
    await setDoc(projectRef, { 
      ...taskValues,
      taskNumber,
      projectId,
      created_at: serverTimestamp(),
    });

    await addInteraction({
      projectId,
      parentType: InteractionParentType.TASK,
      parentId: taskValues.id,
      type: InteractionType.CREATED,
      userId,
    })

  } catch (error) {
    logger.error('addNewTask ', error);
  }
}

const getProjectDetails = async (projectId: string) => {
  try {
    const projectRef = doc(firestoreDb, "projects",projectId);
    const docs = await getDoc(projectRef);
    return {
      ...docs.data(),
      id: docs.id,
    }
  } catch (error) {
    logger.error('getProjectDetails', error)
  }
}

const getUserOrCreate = async (uid: string, user: IUser, create = true) => {
  const userRef = doc(firestoreDb, 'users', uid);

  try {
    const userDoc = await getDoc(userRef);

    if (userDoc.data()) {
      return {
        ...userDoc.data(),
        id: userDoc.id,
      };
    }
  } catch (error) {
    logger.error('getUserOrCreate - get ', error);
    return;
  }

  // create
  if (!create) {
    return;
  }

  try {
    await setDoc(userRef, {
      ...user,
      created_at: serverTimestamp(),
    });
    const userJustCreated = await getDoc(userRef);
    return {
      ...userJustCreated.data(),
      id: userJustCreated.id,
    }
  } catch (error) {
    logger.error('getUserOrCreate - create ', error)
  }
}

const getInviteDetails = async (workspaceId: string, inviteId: string) => {
  const inviteRef = doc(firestoreDb, 'workspaces', workspaceId, 'members', inviteId);

  const inviteDoc = await getDoc(inviteRef);
  if (inviteDoc.data()) {
    return {
      ...inviteDoc.data(),
      id: inviteDoc.id,
    };
  }
}

const getUserByEmail = async (userEmail: string) => {
  const q = query(collection(firestoreDb, "users"), where("email", "==", userEmail));

  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    }
  });

  return docs?.[0]
}

const getUserById = async (userId: string) => {
  try {
    const q = doc(firestoreDb, "users", userId);
  
    const querySnapshot = await getDoc(q);
    return {
      ...querySnapshot.data(),
      id: querySnapshot.id,
    }
  } catch (error) {
    logger.error('getUserById', error)
  }
}

const addUserOnProject = async (userId: string, projectId: string) => {
  try {
    const userRef = doc(firestoreDb, "users", userId);
    await updateDoc(userRef, {
      projects: [
        projectId,
      ]
    });
  } catch (error) {
    logger.error('addUserOnProject ', error);
  }
}

const getProjectsByWorkspaceId = async (workspaceId: string) => {
  try {
    const q = query(collection(firestoreDb, "projects"), where("organizationOwnerId", "==", workspaceId));

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
    });
  } catch (error) {
    logger.error('getProjectsByWorkspaceId ', error);
  }
}

const updateInviteStatus = async (workspaceId: string, inviteId: string, newStatus: string) => {
  try {
    const inviteRef = doc(firestoreDb, "workspaces", workspaceId, "invites", inviteId);
    const inviteDoc = await getDoc(inviteRef);
    if (!inviteDoc) {
      throw new Error("Invite not found");
    }

    await updateDoc(inviteRef, {
      status: newStatus,
      updated_at: serverTimestamp(),
    })
  } catch (error) {
    logger.error('updateInviteStatus ', error);
  }
}

const getMyIssues = async (projectId: string, userId: string) => {
  try {
    const q = query(collection(firestoreDb, "tasks"), where('assigned', '==', userId));

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
    });
  } catch (error) {
    logger.error('getMyIssues ', error);
  }
}

const updateUser = async (userId: string, newUserValues: Partial<IUser>) => {
  try {
    const userRef = doc(firestoreDb, "users", userId);

    await updateDoc(userRef, {
      ...newUserValues,
      updated_at: serverTimestamp(),
    })
  } catch (error) {
    logger.error('updateUser ', error);
  }
};

const addInteraction = async (interaction: Interaction) => {
  try {
    const interactionId = uuid();
    const interactionRef = doc(firestoreDb, "interactions", interactionId);

    await setDoc(interactionRef, {
      ...interaction,
      updated_at: serverTimestamp(),
      created_at: serverTimestamp(),
    });
    // @ts-ignore
    return ((await getDoc(interactionRef)).data()) as Interaction;
  } catch (error) {
    logger.error('addInteraction ', error);
  }
};

const getTaskInteractions = async (projectId: string, taskId: string) => {
  try {
    const q = query(collection(firestoreDb, "interactions"), where('projectId', '==', projectId), where('parentId', '==', taskId), orderBy('created_at', 'desc'));

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
    });
  } catch (error) {
    logger.error('getTaskInteractions ', error);
  }
}

const deleteComment = async (interactionId: string) => {
  try {
    const interactionRef = doc(firestoreDb, "interactions", interactionId);
    await deleteDoc(interactionRef);
  } catch (error) {
    logger.error('deleteComment ', error);
  }
}


export {
  getTasks,
  addNewTask,
  setupProject,
  getProjectDetails,
  getProjectStatus,
  updateCardStatus,
  updateCard,
  removeTask,
  setupWorkspace,
  getWorkspaceMembers,
  deleteWorkspaceMember,
  AddWorkspaceMember,
  getUserOrCreate,
  getInviteDetails,
  getUserByEmail,
  addUserOnProject,
  getProjectsByWorkspaceId,
  updateInviteStatus,
  getMyIssues,
  getUserById,
  updateUser,
  getLastTask,
  addInteraction,
  getTaskInteractions,
}

const api = {
  deleteComment
}

export { api };