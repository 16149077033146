const en = require('./en.json');
const ptBr = require('./pt-BR.json');

const Auth = {
  en,
  ptBr,
}

export {
  Auth
};