export enum MemberStatus {
    // PENDING = 'PENDING',
    INVITE_SENT = 'INVITE_SENT',
    ACCEPTED = 'ACCEPTED',
}

export interface IMember {
    id: string;
    name: string;
    email: string;
    role: string;
    status?: MemberStatus;
}