import React, { ReactPropTypes } from "react";
import UserAvatar from "../../../components/UserAvatar";

type CardProps = { 
  id: string, 
  index: number,
  title?: string,
  body?: string, 
  people?: { field: string, assigned?: string }[],
  onClick: (index: number, id: string) => void,
  status: string,
};

export function Card(props: CardProps) {
  const { title = 'title', body = 'body', people = [], index, id, onClick, status } = props;
  return (
    <div
      className="m-1.5 w-4/5"
      onClick={() => onClick(index, id)}
    >
      <div className="block p-4 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h3>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {body}
        </p>
          <div className="flex flex-row mt-3 space-x-4">
          {!!people.length && (
            people.map((person, index) => (
              <div key={index} className="flex space-x-1">
                <p>
                  {person.field}:
                </p>
                <UserAvatar widht={10} />
              </div>
            ))
          )}
            <div> 
              Status: {status}
            </div>
          </div>
      </div>
    </div>
  )
}