import { ChangeEventHandler, HtmlHTMLAttributes, useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BallLoading } from "../../components/Loading/Ball";
import { setupWorkspace } from "../../services/api";
import { CustomUseContext } from "../../utils/storeApi";

export function Onboarding() {
  const [projectName, setProjectName] = useState('');
  const [taskCode, setTaskCode] = useState('');
  const contextAtt = CustomUseContext();
  const navigate = useNavigate();
  const { t, } = useTranslation();
  const [loading, setLoading] = useState(false);

  const setupOnboarding = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      // @ts-ignore
      await setupWorkspace(contextAtt!.user!, { name: projectName, taskCode });
      setLoading(false);
      window.location.replace('/board');
    } catch (error) {
      setLoading(false);
    }
  }

  const updateProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setProjectName(newName);

    if (!newName.length) {
      return;
    }
    const firstLetter = newName[0].toUpperCase();
    const secondWordFirstLetter = newName.split(' ') && newName.split(' ')[1] ? newName.split(' ')[1][0] : newName[newName.length - 1];
    setTaskCode(`${firstLetter}${secondWordFirstLetter.toUpperCase()}`);

  }

  return (
    <div className="flex content-center justify-center h-full">
      <form className="w-full max-w-lg" onSubmit={setupOnboarding}>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
              {t('Onboarding.labels.projectName')}
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              placeholder={t('Onboarding.labels.sampleProjectName')}
              value={projectName}
              onChange={updateProjectName}
              required
            />
            {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
              {t('Onboarding.labels.identifier')}
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="TR"
              value={taskCode}
              onChange={(event) => setTaskCode(event.target.value.replaceAll(' ', ''))}
              required
              maxLength={3}
            />
          </div>
          <div className="flex justify-center w-full">
            <button
              type="submit"
              className="ml-5 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
              disabled={loading}
            >
              {t('Common.continue')}
              {loading && (
                <div className="ml-2">
                  <BallLoading height="" />
                </div>
              )}
            </button>
          </div>
        </div>
      </form>
    </ div>
  )
}