import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { resources } from './locales/index';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        defaultNS: 'translations',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        i18nFormat: {},
        resources,
    });


export default i18n;