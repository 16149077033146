import { useTranslation } from "react-i18next";
import Table from "../../components/Table";

export default function Backlog() {
  const { t } = useTranslation();

  return (
    <div className="space-y-20">
      <Table
        header={t('backlog.currentSprint')}
        rows={['Add feature to block users', 'Fix on comment edition']}
      />

      <Table
        header={t('backlog.nextSprint')}
        rows={['Add feature to post new videos', 'Fix on photo edition mobile']}
      />

      <Table
        header={'Backlog'}
        rows={['Add notifications', 'Upgrade server to new version']}
      />

    </div>


  )
}