const en = require('./en.json');
const ptBr = require('./pt-BR.json');

const Onboarding = {
  en,
  ptBr,
}

export {
  Onboarding
};