import { useEffect, useState } from "react"
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ArrowBack, HomeOutlined, AccountTreeSharp, ArrowRight, ArrowDropDown } from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import { CustomUseContext } from "../../utils/storeApi";

export default function Settings() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const contextAtt = CustomUseContext();
  const { projects, setProjects } = contextAtt;
  const toggleProject = (index: index, newValue: boolean) => {
    const newProjects = [...projects];
    newProjects[index].show = newValue;
    setProjects(newProjects)
  }

  useEffect(() => {
    navigate("members");
  }, [])

  return (
    <>
    <div className="flex w-full h-screen">
      <aside className="w-52 h-screen" aria-label="Sidebar">
        <div className="overflow-y-auto py-4 px-3 bg-gray-50 rounded dark:bg-gray-800 h-full">
          <div className="mb-9">
            <ArrowBack onClick={() => navigate('/board')}/>
            Settings
          </div>
          <div className="flex flex-col flex-end mb-6">
            <div><HomeOutlined /> Workspace </div>
            <ul className="space-y-2 ml-7">
              <li>
                <Link to='members'>
                  {t('Common.members')}
                </Link>
              </li>
            </ul>
          </div>
          <div 
            className="space-y-2"
          >
            <div><AccountTreeSharp /> {t('Common.projects')}</div>
            <ul className="mt-1">
              {projects.map((project, index) => (
                <li key={index} className="mt-1">
                  <div>
                    <p onClick={() => toggleProject(index, !project.show)}>
                    {project.show ? <ArrowDropDown /> : <ArrowRight />} {project.name}
                    </p>
                    { !!project.show && (
                      <ul className="ml-8">
                          <li className="opacity-40 cursor-not-allowed pointer-events-none"><Link to={`${project.id}/general`}>{t('Common.general')}</Link></li>
                          <li className="opacity-40 cursor-not-allowed pointer-events-none"><Link to={`${project.id}/workflow`}>{t('Common.workflow')}</Link></li>
                          <li className="opacity-40 cursor-not-allowed pointer-events-none"><Link to={`${project.id}/teams`}>{t('Common.teams')}</Link></li>
                      </ul>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </aside>
      <div className="w-full">
        <Outlet />
      </div>
    </ div>
    </>
  )
}