import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Home from './Home';
import MainLayout from './layouts/Main';
import MyIssues from "./screens/MyIssues/index";
import Backlog from "./screens/Backlog";
import Settings from "./screens/Settings";
import Members from "./screens/Settings/Members";
import General from "./screens/Settings/General";
import Teams from "./screens/Settings/Teams";
import Workflow from "./screens/Settings/Workflow";
import { Signup } from "./screens/Auth/Signup";
import { Sign } from "./screens/Auth/Sign";
import { ForgotPassword } from "./screens/Auth/ForgotPassword";
import { useEffect, useState } from "react";
import { CustomUseContext } from "./utils/storeApi";
import { onAuthStateChanged } from "firebase/auth";
import { firebase } from "./services/Firebase";
import { getProjectDetails, getProjectStatus, getUserById, getUserOrCreate, getWorkspaceMembers } from "./services/api";
import { IUser } from "./types/User";
import { Onboarding } from "./screens/Onboarding";
import { Accept } from "./screens/Invites/Accept";

function Redirect({ to }: { to?: string}) {

  const navigate = useNavigate();
  useEffect(() => {
    navigate(to || "/board");
  }, [])

  return <></>
}

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const contextAtt = CustomUseContext();
  if (!contextAtt.user?.id) {
    console.log('ProtectedRoute nao ta logado');
    return <Redirect to="/sign"/>
  }

  console.log('ProtectedRoute retornando childern')
  return <>{children}</>;
}


export function AppRoutes() {
  const [isLoading, setIsLoading] = useState(true);
  const contextAtt = CustomUseContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    onAuthStateChanged(firebase.auth, async (user) => {
      if (user) {
        console.log('onAuthStateChanged - User Logged', user)
        const uid = user.uid;
        // @ts-ignore
        const userOnApi: IUser = await getUserOrCreate(user.uid, {
          // @ts-ignore
          email: user!.email,
        });

        contextAtt?.setUser(userOnApi);

        try {
          // @ts-ignore
          $crisp.push(["set", "user:email", user!.email]);
          if (userOnApi?.name) {
            // @ts-ignore
            $crisp.push(["set", "user:nickname", [userOnApi!.name]]);
          }
        } catch (error) {
          console.log('crisp error', error)
        }


        
        if (!userOnApi?.workspaces?.length && !userOnApi?.projects?.length) {
          console.log('missing workspace or projects')
          navigate('/onboarding');
          setIsLoading(false);
          return;
        }

        // @ts-ignore
        const projectsInfo = await Promise.all(userOnApi?.projects?.map( async (projectId : string) => {
          return await getProjectDetails(projectId)
        }));

        // @ts-ignore
        contextAtt?.setCurrentProject(projectsInfo?.[0].id, projectsInfo?.[0]);

        // @ts-ignore
        const workspaceMembers = await getWorkspaceMembers(userOnApi?.workspaces[0]);

        // @ts-ignore
        const membersDetails = await Promise.all(workspaceMembers?.filter((m) => !!m.userId).map((member) => getUserById(member.userId)))

        // @ts-ignore
        const projectStatus = await getProjectStatus(userOnApi?.projects?.[0]);

        const statusObject = {};
        projectStatus?.forEach((status) => {
          // @ts-ignore
          statusObject[status.id] = { ...status };
        });

        // @ts-ignore
        contextAtt.setStatus(statusObject);

        // @ts-ignore
        contextAtt.setProjects(projectsInfo);
        // @ts-ignore
        contextAtt.setMembers(membersDetails);

        if (location.pathname.match(/invites\/accept/)) {
          setIsLoading(false);
          return;
        }

        if (location.pathname.match(/sign/)) {
          navigate('/board');
          setIsLoading(false);
          return;
        }

        if (location.pathname.match(/onboarding/)) {
          navigate('/board');
          setIsLoading(false);
          return;
        }

        setIsLoading(false);

      } else {
        console.log('onAuthStateChanged - User Not Logged', user)
        if (location.pathname.match(/invites\/accept/)) {
          setIsLoading(false);
          return;
        }
        console.log('indo pra sign')
        navigate('/sign',)
        setIsLoading(false);
      }
    });
  }, []);

  if (isLoading) {
    return <>Loading......</>
  }

  return (
    <Routes>
      <Route path="/">
        <Route path="/invites/accept" element={<Accept />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/sign" element={<Sign />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/onboarding" element={<ProtectedRoute><Onboarding /></ProtectedRoute>} />
        <Route path="/board" element={<ProtectedRoute><MainLayout><Home /></MainLayout></ProtectedRoute>} />
        <Route path="/my-issues" element={<ProtectedRoute><MainLayout><MyIssues /></MainLayout></ProtectedRoute>} />
        <Route path="/backlog" element={<ProtectedRoute><MainLayout><Backlog /></MainLayout></ProtectedRoute>} />
        <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>}>
          <Route path="members" element={<Members />} />
          <Route path=":projectId/general" element={<General />} />
          <Route path=":projectId/teams" element={<Teams />} />
          <Route path=":projectId/workflow" element={<Workflow />} />
          <Route path='*' element={<Redirect />} />
        </Route>
        <Route path='*' element={<Redirect />} />
        <Route path='/' element={<Redirect />} />
      </Route>
    </Routes>
  )
}