import { Timestamp } from "firebase/firestore";

export enum InteractionType {
  COMMENTED = 'COMMENTED',
  CREATED = 'CREATED'
  // UPDATE = 'UPDATE',
  // DELETE = 'DELETE'
}

export enum InteractionParentType {
  TASK = 'TASK',
  // COMMENT = 'COMMENT',
}


export interface Interaction {
  id?: string;
  type: InteractionType;
  parentId?: string;
  parentType: InteractionParentType;
  projectId:string;
  userId: string;
  value?: string;
  created_at?: Timestamp;
}