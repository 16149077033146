import './App.css';
import { useRef, useState } from "react";
import StoreApi, { IContextType } from "./utils/storeApi";
import { AppRoutes } from "./routes";
import { IUser } from "./types/User";
import { addNewTask } from './services/api';
import { v4 as uuid } from "uuid";
import { ITask } from './types/Task';
import { IProject } from './types/IProject';
import { IMember } from './types/Member';
import { IStatus } from './types/IStatus';

function App() {
  const setUser = (newUser: IUser) => {
    setContext({
      ...currentContextRef.current,
      user: { ...newUser }
    });
  }
  const setCurrentProject = (projectId: string, project: IProject) => {
    setContext({
      ...currentContextRef.current,
      currentProject: { 
        ...project,
        id: projectId,
       }
    });
  };
  const setProjects = (projects: Array<IProject>) => {
    setContext({
      ...currentContextRef.current,
      projects,
    });
  };

  const setMembers = (members: Array<IMember>) => {
    setContext({
      ...currentContextRef.current,
      members,
    });
  }

  const setStatus = (status: IStatus) => {
    setContext({
      ...currentContextRef.current,
      status,
    });
  }

  const updateCardTitle = (title: string, index: number, listId: string) => {
    console.log('updateCardTitle not implemented')
  }

  const initialContext: IContextType = {
    clickedCard: (task) => {
      currentContextRef.current.setSelectedTask(task);
    },
    user: undefined,
    setUser,
    setCurrentProject,
    updateCardTitle,
    selectedTask: null,
    setSelectedTask: (task) => {
      setContext({
        ...currentContextRef.current,
        selectedTask: task
      })
    },
    addMoreCard(title, listId) {
      if (!title) {
        return;
      }
  
      const newCardId = uuid();
      const newCard: ITask = {
        id: newCardId,
        title,
        statusId: listId,
        description: '',
      };

      addNewTask(newCard, currentContextRef.current.currentProject!.id!, currentContextRef.current.user?.id!);
    },
    projects: null,
    setProjects,
    members: [],
    setMembers,
    status: {},
    setStatus,
  }

  const [context, _setContext] = useState({ ...initialContext });
  const currentContextRef = useRef(context);
  // @ts-ignore
  const setContext = data => {
    currentContextRef.current = data;
    _setContext(data);
  };

  return (
    <StoreApi.Provider value={context}>
      <AppRoutes />
    </StoreApi.Provider>
  )
}

export default App;
