const en = require('./en.json');
const ptBr = require('./prBr.json');


const Common = {
  en,
  ptBr,
}

export {
  Common
};