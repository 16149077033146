type HeaderProps = {
  title: string;
  description: string;
}
export default function Header({title, description, } : HeaderProps) {
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <h3 className="">{title}</h3>
        <p>{description}</p>
      </div>
    </>
  )
}